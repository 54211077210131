import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class FeedItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: false
    }

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  onMouseEnter() {
    this.setState({
      isHovering: true
    })
  }

  onMouseLeave() {
    this.setState({
      isHovering: false
    })
  }

  render() {    
    const { project, image } = this.props
    const gatsbyImage = getImage(image)
    
    // Get orientation either from gatsbyImageData or fall back to the original fluid data
    let orientation = 'landscape'
    if (gatsbyImage?.width && gatsbyImage?.height) {
      orientation = (gatsbyImage.width / gatsbyImage.height) < 1 ? 'portrait' : 'landscape'
    } else if (image?.fluid?.aspectRatio) {
      orientation = image.fluid.aspectRatio < 1 ? 'portrait' : 'landscape'
    }

    if (!gatsbyImage && !image?.fluid) {
      console.warn('No valid image data found for FeedItem')
      return null
    }

    return (
      <div 
        className={`feedItem feedItem--${orientation}`} 
        onMouseEnter={this.onMouseEnter} 
        onMouseLeave={this.onMouseLeave}
      >
        <Link to={`/project/${project.slug}`}>
          <GatsbyImage
            image={gatsbyImage}
            alt={project.title}
            loading="eager"
            quality={85}
            placeholder="none"
            className="feed-item-image"
          />
        </Link>
        
        {project.title !== undefined && (
          <div
            className="feedItemTitle"
            style={{ opacity: (this.state.isHovering ? 1 : 0) }}
            dangerouslySetInnerHTML={{
              __html: project.title
            }}
          />
        )}
      </div>
    )
  }
}

export default FeedItem